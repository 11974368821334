<template>
	<div>
		<b-row v-if="followUps.length > 0 && $screen.width >= 992" class="form">
			<b-col sm="12" md="12" lg="6" xl="6" class="pb-0">
				<b-input-group v-if="$screen.width >= 992">
					<b-form-input v-model="filter" type="text" :placeholder="FormMSG(2, 'Type to Search')" @change="handleChangeFilter" />
					<b-input-group-append class="cursor-pointer">
						<b-input-group-text class="btn-search">
							<Search color="#FFFFFF" :size="16" class="icon" v-if="filter.length === 0" :stroke-width="2.5" />
							<X color="#FFFFFF" :size="16" class="icon" v-else :stroke-width="2.5" @click="handleChangeFilter('')" />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-overlay :show.sync="isBusyDataTable" rounded="lg" class="w-100">
				<b-col>
					<CardListBuilder
						v-if="$screen.width < 992"
						:items="followUps"
						:fields="followUpFields"
						:omitted-fields="['sendReminder', 'packageDocument']"
					>
						<template slot="actions" slot-scope="data">
							<b-button variant="primary" size="sm" class="mr-2" @click="handleClickPackageDocument(data.item)">
								<eye :size="16" />
							</b-button>
							<b-button variant="primary" size="sm" class="mr-2" @click="handleClickSendReminder(data.item)">
								<timer-reset :size="14" />
							</b-button>
						</template>
					</CardListBuilder>
					<b-table
						v-if="$screen.width >= 992"
						hover
						responsive
						:items="followUps"
						sort-by="name"
						:filter="filter"
						:fields="followUpFields"
						sticky-header="700px"
						head-variant="dark"
						bordered
						small
						:empty-text="FormMSG(567, 'No data found')"
						show-empty
					>
						<template #cell(viewDocument)="data">
							<button class="btn-transparent text-color-rhapsody-in-blue" @click="handlePreviewDocument(data.item)">
								<eye :size="16" />
							</button>
						</template>
						<template #cell(statusDocument)="data">
							<div
								class="step-container"
								:class="{
									'status-doc-validated': data.item.documentStatus === 5
								}"
							>
								<div>
									{{
										data.item.documentStatus === 5
											? FormMSG(59, 'Validated')
											: formatShowingSenderStatus(data.item.senderStatus, data.item.currentStep, data.item.documentStatus)
									}}
								</div>
							</div>
						</template>
						<template #cell(approveDocument)="data">
							<button class="btn-transparent" :disabled="!data.item.needValidation" @click="handleClickApprove(data.item)">
								<check-circle :size="16" color="#00A09C" />
							</button>
						</template>
						<template #cell(id)="data">
							<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleClickShowModal(data.item)">
								<send :size="16" />
							</button>
						</template>
						<template #cell(refuse)="data">
							<b-button variant="danger" size="sm" @click="handleClickRefuse(data.item)">
								<X :size="16" />
							</b-button>
						</template>
					</b-table>
				</b-col>
			</b-overlay>
		</b-row>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(1000, 'Reminder message')"
			:ok-title="FormMSG(1002, 'Send reminder')"
			:cancel-title="FormMSG(1003, 'Cancel')"
			:hide-header-close="true"
			modal-class="modal-mobile"
			v-model="isOpen"
			cancel-variant="light"
			@ok="handleClickSendReminder"
			@cancel="onCancel"
			@hidden="onCancel"
			size="md"
			no-close-on-backdrop
			no-close-on-esc
		>
			<div class="form">
				<b-form @submit="handleClickSendReminder">
					<b-form-group :label="FormMSG(4, 'Title')" label-for="reminder-title" :label-cols="12">
						<b-form-textarea id="reminder-title" ref="reminder-title" v-model="messages" rows="3" autofocus />
					</b-form-group>
				</b-form>
			</div>
		</b-modal>
		<dialog-for-refuse-document
			:open="showDialogRefuseDocument"
			:document-selected="documentSelected"
			@dialog-for-refuse-document:success="handleDialogForRefuseDocumentSuccess"
			@dialog-for-refuse-document:close="handleDialogForRefuseDocumentClose"
		/>
	</div>
</template>

<script>
import { TimerReset, Eye, Search, X, CheckCircle, Send } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';
import DialogForRefuseDocument from '@/modules/onboarding/components/DialogForRefuseDocument';
import { getDocument } from '@/modules/document-package/cruds/document.crud';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import globalMixin from '@/mixins/global.mixin';
import { validateDocument } from '@/modules/onboarding/cruds/onboarding.crud';
import { store } from '@/store';
import { sendReminder } from '@/modules/onboarding/cruds/onboarding.crud.js';
import * as _ from 'lodash';

export default {
	name: 'FollowUp',
	components: {
		TimerReset,
		Eye,
		Search,
		X,
		CheckCircle,
		DialogForRefuseDocument,
		Send
	},
	mixins: [languageMessages, globalMixin],
	props: {
		followUpsList: { type: Array, default: [] },
		forValidation: { type: Boolean, default: true },
		isBusy: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			isOpen: false,
			curDoc: {},
			followUps: [],
			filter: '',
			messages: '',
			followUpsOriginal: [],
			showDialogRefuseDocument: false,
			documentPackageIdSelected: 0,
			documentSelected: {},
			isBusyDataTable: false
		};
	},
	computed: {
		followUpFields() {
			var ret = [];
			ret = [
				// {key: 'id'},
				{
					label: this.FormMSG(65, 'Department & Function'),
					key: 'departmentFunction',
					sortable: true,
					class: 'text-left',
					formatter: (value, key, item) => {
						return `${item.departmentName} - ${item.functionName}`;
					}
				},
				{
					label: this.FormMSG(66, 'Full Name'),
					key: 'fullName',
					sortable: true,
					class: 'text-left',
					formatter: (value, key, item) => {
						return `${item.name} ${item.firstName}`;
					}
				},
				{
					label: this.FormMSG(67, 'Budget category'),
					key: 'accountingCode',
					sortable: true,
					class: 'text-left',
					formatter: (value, key, item) => {
						return `${item.accountingCode} `;
					}
				},
				{
					label: this.FormMSG(68, 'Contract date'),
					key: 'contractDate',
					sortable: true,
					class: 'text-left',
					formatter: (value, key, item) => {
						return `${this.formatContractDate(item.contractStartDate)} - ${this.formatContractDate(item.contractEndDate)}`;
					}
				},
				{
					label: this.FormMSG(69, 'Package'),
					key: 'documentName',
					sortable: true,
					class: 'text-left'
				},
				{
					label: this.FormMSG(70, 'View'),
					key: 'viewDocument',
					sortable: true,
					class: 'text-center'
				},
				{
					label: this.FormMSG(71, 'Status'),
					key: 'statusDocument',
					class: 'text-center',
					sortable: true
				},
				{
					label: this.FormMSG(710, 'Send reminder'),
					key: 'id',
					class: 'text-center',
					sortable: false
				}
			];

			if (this.forValidation) {
				ret.push({
					label: this.FormMSG(178, 'Approve'),
					key: 'approveDocument',
					sortable: true,
					class: 'text-center'
				});

				ret.push({
					label: this.FormMSG(173, 'Refuse'),
					key: 'refuse',
					sortable: true,
					class: 'text-center',
					variant: 'danger'
				});
			}

			return ret;
		}
	},
	watch: {
		followUpsList: {
			handler(val) {
				this.followUps = val;
				this.followUpsOriginal = val;
			},
			immediate: true,
			deep: true
		},
		isBusy: {
			handler(value) {
				if (!_.isNil(value)) {
					this.isBusyDataTable = value;
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		formatShowingSenderStatus(senderStatus, currentStep, documentStatus) {
			const statues = [0, 1, 2];
			if (statues.includes(documentStatus) && currentStep === 1) {
				return this.getLabelSenderStatus(senderStatus);
			}

			return this.getLabelSenderStatus(senderStatus) + ' ' + this.getLabelStep(currentStep);
		},
		onCancel() {
			this.isOpen = false;
		},
		handleClickShowModal(item) {
			this.curDoc = Object.assign({}, item);
			this.isOpen = true;
		},
		handleDialogForRefuseDocumentSuccess(payload) {
			this.$emit('follow-up:document-refused');
		},
		async handleClickApprove(item) {
			try {
				const action = async () => {
					this.isBusyDataTable = true;
					await validateDocument(item.documentDeliveryId);

					this.$emit('follow-up:validate-document', item);
					this.isBusyDataTable = false;
					this.createToastForMobile(this.FormMSG(24, 'Success'), this.FormMSG(25, 'Document validated successfully'), '', 'success');
				};

				this.confirmModal(action, this.FormMSG(214, 'The document will go to the next step if you accept'));
			} catch (error) {
				console.log(error);
			}
		},
		async handlePreviewDocument(item) {
			// alert(item.toSign)
			const document = await getDocument(item.docId, null, item.userId, item.contractId);

			if (document.type !== 11) {
				const isToSign = this.isToSign(item.toSign, document.senderStatus);
				let list = {
					...document,
					toSign: item.toSign,
					fileName: this.replaceToRightFileNameExt(document.fileName, document.docSignedXid),
					status: isToSign ? 3 : document.senderStatus,
					src:
						getFileSrc(document, {
							requestId: store.userID(),
							userId: item.userId,
							contractId: item.documentDeliveryContractId
						}) +
						'?forceViewAsManager=true&deliveryParentId=' +
						document.firstDeliveryParentId,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(document.xid, document.docSignedXid),
					ext: getFileExtension(this.getRightXidDocument(document.xid, document.docSignedXid)),
					docValidationLevel: item.docValidationLevel
				};

				if (!this.forValidation) {
					list = {
						...document,
						fileName: this.replaceToRightFileNameExt(document.fileName, document.docSignedXid),
						src: getFileSrc(document, {
							requestId: store.userID(),
							userId: item.userId,
							contractId: item.documentDeliveryContractId
						}),
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(document.xid, document.docSignedXid),
						ext: getFileExtension(this.getRightXidDocument(document.xid, document.docSignedXid)),
						docValidationLevel: item.docValidationLevel
					};
				}

				this.$previewImages({
					name: 'document-previewer-followup',
					images: [list],
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						showStatusFileTag: true,
						hideCommentButton: true,
						hideDisLikeButton: true,
						hideLikeDislike: true,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: this.forValidation
					}
				});
			}

			if (document.childDocList.length > 0 && document.type === 11) {
				const list = document.childDocList.map((doc) => {
					if (this.forValidation) {
						return {
							...doc,
							toSign: item.toSign,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							status: this.isToSign(item.toSign, doc.senderStatus) ? 3 : doc.senderStatus,
							src:
								getFileSrc(doc, {
									requestId: store.userID(),
									userId: item.userId,
									contractId: item.documentDeliveryContractId
								}) +
								'?forceViewAsManager=true&deliveryParentId=' +
								doc.firstDeliveryParentId,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(doc.xid, doc.docSignedXid),
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							docValidationLevel: item.docValidationLevel
						};
					} else {
						return {
							...doc,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							src: getFileSrc(doc, {
								requestId: store.userID(),
								userId: item.userId,
								contractId: item.documentDeliveryContractId
							}),
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(doc.xid, doc.docSignedXid),
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							docValidationLevel: item.docValidationLevel
						};
					}
				});

				this.$previewImages({
					name: 'package-previewer-followup',
					images: list,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						showStatusFileTag: true,
						hideCommentButton: true,
						hideDisLikeButton: true,
						hideLikeDislike: true,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: this.forValidation
					}
				});
			} else if (document.childDocList.length === 0 && document.type === 11) {
				this.createToastForMobile(this.FormMSG(156, 'Error'), this.FormMSG(157, 'There is no file in the package'), '', 'danger');
			}
		},
		handleClickRefuse(item) {
			this.showDialogRefuseDocument = true;
			this.documentSelected = item;
		},
		handleDialogForRefuseDocumentClose() {
			this.showDialogRefuseDocument = false;
			this.documentSelected = {};
		},
		handleChangeFilter(payload) {
			this.followUps = [];
			let filter = payload.trim().toLowerCase();
			if (filter === '') {
				this.filter = '';
				this.followUps = this.followUpsOriginal;
			} else {
				this.followUps = this.followUpsOriginal;
				let arr = this.followUps.filter((f) => {
					return (
						f.name.toLowerCase().indexOf(filter) > -1 ||
						f.firstname.toLowerCase().indexOf(filter) > -1 ||
						f.accountingCode.toLowerCase().indexOf(filter) > -1 ||
						f.contractDate.toLowerCase().indexOf(filter) > -1 ||
						f.departmentName.toLowerCase().indexOf(filter) > -1
					);
				});

				this.filter = filter;
				this.followUps = arr;
			}
		},
		handleClickPackageDocument(payload) {
			// alert('package document');
		},
		async handleClickSendReminder() {
			let docId = this.curDoc.docId;
			let message = this.messages;
			const isReminded = await sendReminder(docId, message);
			if (isReminded) {
				var strTitle = this.FormMSG(162, 'Success');
				this.$bvToast.toast(this.FormMSG(1610, 'The reminder was sent successfully'), {
					title: strTitle,
					variant: 'success',
					solid: true
				});
			} else {
				var strTitle = this.FormMSG(164, 'Failed');
				this.$bvToast.toast(this.FormMSG(1610, 'There was a problem when sending reminder, please try again'), {
					title: strTitle,
					variant: 'danger',
					solid: true
				});
			}
			this.messages = '';
		},
		formatContractDate(value) {
			return moment(new Date(value)).format('DD-MM-YYYY');
		},
		validatedStatus(value) {
			const statuses = this.FormMenu(1012);
			const findValue = statuses.find((s) => (s.value = value));

			return findValue;
		},
		getLabelStep(value) {
			if (value === 0) {
				return this.FormMSG(184, 'Unread');
			}
			if (value === 1) {
				return this.FormMSG(84, 'Step 1');
			}
			if (value === 2) {
				return this.FormMSG(85, 'Step 2');
			}
			if (value === 3) {
				return this.FormMSG(86, 'Step 3');
			}
		},
		getLabelSenderStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1012, value);
		},
		isToSign(toSign, senderStatus) {
			if (toSign && senderStatus !== 4) {
				return true;
			}

			return false;
		}
	}
};
</script>
