var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.followUps.length > 0 && _vm.$screen.width >= 992
        ? _c(
            "b-row",
            { staticClass: "form" },
            [
              _c(
                "b-col",
                {
                  staticClass: "pb-0",
                  attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(2, "Type to Search"),
                            },
                            on: { change: _vm.handleChangeFilter },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            { staticClass: "cursor-pointer" },
                            [
                              _c(
                                "b-input-group-text",
                                { staticClass: "btn-search" },
                                [
                                  _vm.filter.length === 0
                                    ? _c("Search", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "#FFFFFF",
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                      })
                                    : _c("X", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "#FFFFFF",
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChangeFilter("")
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-overlay",
            {
              staticClass: "w-100",
              attrs: { show: _vm.isBusyDataTable, rounded: "lg" },
              on: {
                "update:show": function ($event) {
                  _vm.isBusyDataTable = $event
                },
              },
            },
            [
              _c(
                "b-col",
                [
                  _vm.$screen.width < 992
                    ? _c("CardListBuilder", {
                        attrs: {
                          items: _vm.followUps,
                          fields: _vm.followUpFields,
                          "omitted-fields": ["sendReminder", "packageDocument"],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "actions",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickPackageDocument(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [_c("eye", { attrs: { size: 16 } })],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickSendReminder(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("timer-reset", {
                                        attrs: { size: 14 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1777709547
                        ),
                      })
                    : _vm._e(),
                  _vm.$screen.width >= 992
                    ? _c("b-table", {
                        attrs: {
                          hover: "",
                          responsive: "",
                          items: _vm.followUps,
                          "sort-by": "name",
                          filter: _vm.filter,
                          fields: _vm.followUpFields,
                          "sticky-header": "700px",
                          "head-variant": "dark",
                          bordered: "",
                          small: "",
                          "empty-text": _vm.FormMSG(567, "No data found"),
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(viewDocument)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePreviewDocument(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [_c("eye", { attrs: { size: 16 } })],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(statusDocument)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "step-container",
                                      class: {
                                        "status-doc-validated":
                                          data.item.documentStatus === 5,
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              data.item.documentStatus === 5
                                                ? _vm.FormMSG(59, "Validated")
                                                : _vm.formatShowingSenderStatus(
                                                    data.item.senderStatus,
                                                    data.item.currentStep,
                                                    data.item.documentStatus
                                                  )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(approveDocument)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-transparent",
                                      attrs: {
                                        disabled: !data.item.needValidation,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickApprove(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("check-circle", {
                                        attrs: { size: 16, color: "#00A09C" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(id)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickShowModal(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [_c("send", { attrs: { size: 16 } })],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(refuse)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "danger", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickRefuse(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [_c("X", { attrs: { size: 16 } })],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3632809876
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(1000, "Reminder message"),
            "ok-title": _vm.FormMSG(1002, "Send reminder"),
            "cancel-title": _vm.FormMSG(1003, "Cancel"),
            "hide-header-close": true,
            "modal-class": "modal-mobile",
            "cancel-variant": "light",
            size: "md",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: {
            ok: _vm.handleClickSendReminder,
            cancel: _vm.onCancel,
            hidden: _vm.onCancel,
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-form",
                { on: { submit: _vm.handleClickSendReminder } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(4, "Title"),
                        "label-for": "reminder-title",
                        "label-cols": 12,
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        ref: "reminder-title",
                        attrs: {
                          id: "reminder-title",
                          rows: "3",
                          autofocus: "",
                        },
                        model: {
                          value: _vm.messages,
                          callback: function ($$v) {
                            _vm.messages = $$v
                          },
                          expression: "messages",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("dialog-for-refuse-document", {
        attrs: {
          open: _vm.showDialogRefuseDocument,
          "document-selected": _vm.documentSelected,
        },
        on: {
          "dialog-for-refuse-document:success":
            _vm.handleDialogForRefuseDocumentSuccess,
          "dialog-for-refuse-document:close":
            _vm.handleDialogForRefuseDocumentClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }